import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import {Product} from "../../modals/product.model";
import {CartItem} from "../../modals/cart-item";
import {ProductService} from "../shared/services/product.service";
import {CartService} from "../shared/services/cart.service";
import { Router, NavigationEnd } from '@angular/router';
import { SidebarMenuService } from '../shared/sidebar/sidebar-menu.service';
import { SidenavMenu } from '../shared/sidebar/sidebar-menu.model';

import { AuthentificationService } from "../../services/authentification.service";

import { Observable } from "rxjs";

@Component({
  selector: "app-main",
  templateUrl: "./main.component.html",
  styleUrls: ["./main.component.scss"],
})
export class MainComponent implements OnInit {
  public sidenavMenuItems: Array<any>;

  public currencies = ["USD", "EUR"];
  public currency: any;
  public flags = [
    { name: "English", image: "assets/images/flags/gb.svg" },
    { name: "German", image: "assets/images/flags/de.svg" },
    { name: "French", image: "assets/images/flags/fr.svg" },
    { name: "Russian", image: "assets/images/flags/ru.svg" },
    { name: "Turkish", image: "assets/images/flags/tr.svg" },
  ];
  public flag: any;

  products: Product[];

  indexProduct: number;
  shoppingCartItems: CartItem[] = [];

  public banners = [];

  wishlistItems: Product[] = [];

  public url: any;

  navItems: SidenavMenu[] = [

    {
      displayName: "Accueil",
      iconName: "feedback",
      route: "/pages/accueil",
    },
    {
      displayName: "Location",
      iconName: "feedback",
      route: "/page/location/all",
    },
    {
      displayName: "Connexion",
      iconName: "feedback",
      route: "/pages/mon-compte",
    },
  ];

  navItemsConnecte: SidenavMenu[] = [

    {
      displayName: "Accueil",
      iconName: "feedback",
      route: "/pages/accueil",
    },
    {
      displayName: "Location",
      iconName: "feedback",
      route: "/page/location/all",
    },
    {
      displayName: "Deconnexion",
      iconName: "feedback",
      route: "/pages/logout",
    },
  ];



  constructor(
    public router: Router,
    private cartService: CartService,
    public sidenavMenuService: SidebarMenuService,
    public auth: AuthentificationService
  ) {
    this.cartService
      .getItems()
      .subscribe(
        (shoppingCartItems) => (this.shoppingCartItems = shoppingCartItems)
      );
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.url = event.url;
      }
    });

  }

  ngAfterViewInit() {}

  ngOnInit() {
    this.currency = this.currencies[0];
    this.flag = this.flags[0];
  }

  public changeCurrency(currency) {
    this.currency = currency;
  }
  public changeLang(flag) {
    this.flag = flag;
  }
}
