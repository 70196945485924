import { Component, OnInit } from '@angular/core';
import { Product } from 'src/app/modals/product.model';
import { CartItem } from 'src/app/modals/cart-item';
import { CartService } from '../services/cart.service';

import { AuthentificationService } from "../../../services/authentification.service";
import { Router } from '@angular/router';

@Component({
  selector: 'app-header-two',
  templateUrl: './header-two.component.html',
  styleUrls: ['./header-two.component.scss']
})
export class HeaderTwoComponent implements OnInit {

  public currencies = ['FCFA', 'USD'];
  public currency:any;
  public flags = [
    { name:'English', image: 'assets/images/flags/gb.svg' },
    { name:'German', image: 'assets/images/flags/de.svg' },
    { name:'French', image: 'assets/images/flags/fr.svg' },
    { name:'Russian', image: 'assets/images/flags/ru.svg' },
    { name:'Turkish', image: 'assets/images/flags/tr.svg' }
  ]
  public flag:any;

  regions = [
    'Region',
    'Dakar',
    'Diourbel',
    'Fatick',
    'Kaffrine',
    'Kaolack',
    'Kedougou',
    'Kolda',
    'Louga',
    'Matam',
    'Saint-Louis',
    'Sedhiou',
    'Tambacounda',
    'Thies',
    'Ziguinchor',
  ];

  regions_choix = [
    {region: 'Region', libelle: 'Region'},
    {region: 'all', libelle: 'Tous'},
    {region: 'Dakar', libelle: 'Dakar'},
    {region: 'Diourbel', libelle: 'Diourbel'},
    {region: 'Fatick', libelle: 'Fatick'},
    {region: 'Kaffrine', libelle: 'Kaffrine'},
    {region: 'Kaolack', libelle: 'Kaolack'},
    {region: 'Kedougou', libelle: 'Kedougou'},
    {region: 'Kolda', libelle: 'Kolda'},
    {region: 'Louga', libelle: 'Louga'},
    {region: 'Matam', libelle: 'Matam'},
    {region: 'Saint-Louis', libelle: 'Saint-Louis'},
    {region: 'Sedhiou', libelle: 'Sedhiou'},
    {region: 'Tambacounda', libelle: 'Tambacounda'},
    {region: 'Thies', libelle: 'Thies'},
    {region: 'Ziguinchor', libelle: 'Ziguinchor'}
  ];

  regions_choix_depart = [
    {region: 'Region', libelle: 'Region départ'},
    {region: 'all', libelle: 'Tous'},
    {region: 'Dakar', libelle: 'Dakar'},
    {region: 'Diourbel', libelle: 'Diourbel'},
    {region: 'Fatick', libelle: 'Fatick'},
    {region: 'Kaffrine', libelle: 'Kaffrine'},
    {region: 'Kaolack', libelle: 'Kaolack'},
    {region: 'Kedougou', libelle: 'Kedougou'},
    {region: 'Kolda', libelle: 'Kolda'},
    {region: 'Louga', libelle: 'Louga'},
    {region: 'Matam', libelle: 'Matam'},
    {region: 'Saint-Louis', libelle: 'Saint-Louis'},
    {region: 'Sedhiou', libelle: 'Sedhiou'},
    {region: 'Tambacounda', libelle: 'Tambacounda'},
    {region: 'Thies', libelle: 'Thies'},
    {region: 'Ziguinchor', libelle: 'Ziguinchor'}
  ]

  regions_choix_destination = [
    {region: 'Region', libelle: 'Region destination'},
    {region: 'all', libelle: 'Tous'},
    {region: 'Dakar', libelle: 'Dakar'},
    {region: 'Diourbel', libelle: 'Diourbel'},
    {region: 'Fatick', libelle: 'Fatick'},
    {region: 'Kaffrine', libelle: 'Kaffrine'},
    {region: 'Kaolack', libelle: 'Kaolack'},
    {region: 'Kedougou', libelle: 'Kedougou'},
    {region: 'Kolda', libelle: 'Kolda'},
    {region: 'Louga', libelle: 'Louga'},
    {region: 'Matam', libelle: 'Matam'},
    {region: 'Saint-Louis', libelle: 'Saint-Louis'},
    {region: 'Sedhiou', libelle: 'Sedhiou'},
    {region: 'Tambacounda', libelle: 'Tambacounda'},
    {region: 'Thies', libelle: 'Thies'},
    {region: 'Ziguinchor', libelle: 'Ziguinchor'}
  ]


  products: Product[];
  indexProduct: number;
  public sidenavMenuItems:Array<any>;
  shoppingCartItems: CartItem[] = [];


  public regionchoisie = "Region";
  public regionDepartChoisie = "Region";
  public regionDestinationChoisie = "Region";

  constructor(
    private cartService: CartService,
     public auth: AuthentificationService,
     private router: Router,

    ) {
    this.cartService.getItems().subscribe(shoppingCartItems => this.shoppingCartItems = shoppingCartItems);
   }

  ngOnInit() {
    this.currency = this.currencies[0];
    this.flag = this.flags[0];
  }
  public changeCurrency(currency){
    this.currency = currency;
  }
  public changeLang(flag){
    this.flag = flag;
  }

  choixRegion(region) {
    console.log(region.target.data);
  }

  async chercher(){
    let regionChoisie = this.regionchoisie;
    if (this.regionchoisie == "Region"){
      regionChoisie = "all"
    }
    let page = "/page/location/"+regionChoisie;
    this.router.navigate([page]);
  }

  async chercherCovoiturage(){

    let regionDepartChoisie = this.regionDepartChoisie;
    if (this.regionDepartChoisie == "Region"){
      regionDepartChoisie = "all"
    }

    let regionDestinationChoisie = this.regionDestinationChoisie;
    if (this.regionDestinationChoisie == "Region"){
      regionDestinationChoisie = "all"
    }

    let page = "/page/covoiturage/"+regionDepartChoisie+"/"+regionDestinationChoisie;
    this.router.navigate([page]);
  }
}


