import { Component } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { Settings, AppSettings } from './components/shared/services/color-option.service';
import { Router, NavigationEnd } from '@angular/router';

import { AuthentificationService } from "./services/authentification.service";


@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  title = "ecommerce-sophia-new";
  public settings: Settings;
  public url: any;

  public infosUser: any = "";
  public token: any = "";

  public test: any = "";

  constructor(
    private spinner: NgxSpinnerService,
    public appSettings: AppSettings,
    public router: Router,
    public auth: AuthentificationService
  ) {
    this.settings = this.appSettings.settings;
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.url = event.url;
      }
    });

    this.infosUser = JSON.parse(localStorage.getItem("infosUser"));
    this.token = localStorage.getItem("token");
    this.test = localStorage.getItem("boubacar");
    console.log(this.infosUser)
    if (this.infosUser == null || this.infosUser == "") {
      console.log("utilisateur non connecte");
    }else{
      this.auth.setInfosUser(this.infosUser);
      this.auth.setToken(this.token);
      this.auth.setIsConnected(true);
    }

  }

  ngOnInit() {
    /** spinner starts on init */
    this.spinner.show();

    setTimeout(() => {
      /** spinner ends after 5 seconds */
      this.spinner.hide();
    }, 5000);

    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }
}
