import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { CartService } from 'src/app/components/shared/services/cart.service';
import { ProductService } from 'src/app/components/shared/services/product.service';
import { WishlistService } from 'src/app/components/shared/services/wishlist.service';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Product } from 'src/app/modals/product.model';
import { ProductDialogComponent } from '../product-dialog/product-dialog.component';
import {MatCardModule} from '@angular/material/card';


import { CarService } from 'src/app/services/car.service';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss'],

})
export class ProductComponent implements OnInit {

  @Output() onOpenProductDialog: EventEmitter<any> = new EventEmitter();
  @Input() product: any;
  @Input() type_voiture: any;
  @Input() type_page: any = '';

 public url: any;

  constructor(private cartService: CartService, public productsService: ProductService, private wishlistService: WishlistService, private dialog: MatDialog, private router: Router,
    private carService: CarService ) { }

  ngOnInit() {
    this.url = this.carService.getUrl();
    console.log(this.type_voiture);
  }

  urlImage(image) {
    let imageurl = this.url + "uploads/" + image;
    return imageurl;
  }

  async voirDetail(voiture, type_voiture='location'){
    type_voiture = (this.type_voiture != 'covoiturage') ? 'location' : 'covoiturage';
    //console.log(voiture);
    localStorage.setItem("detailVoiture", JSON.stringify(voiture));
    localStorage.setItem("type_voiture", type_voiture);
    this.router.navigate(['/page/product', 1, type_voiture]);
  }

     // Add to cart
     public addToCart(product: Product,  quantity: number = 1) {
      this.cartService.addToCart(product,quantity);
      console.log(product, quantity);
    }

    // Add to wishlist
    public addToWishlist(product: Product) {
      this.wishlistService.addToWishlist(product);
   }

    // Add to compare
    public addToCompare(product: Product) {
      this.productsService.addToCompare(product);
   }


  public openProductDialog(product){
    let dialogRef = this.dialog.open(ProductDialogComponent, {
        data: product,
        panelClass: 'product-dialog',
    });
    dialogRef.afterClosed().subscribe(product => {
      if(product){
        this.router.navigate(['/products', product.id, product.name]);
      }
    });
  }

}
