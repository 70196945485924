import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { ProductDetailsComponent } from './products/product-details/product-details.component';
import { ProductLeftSidebarComponent } from './products/product-left-sidebar/product-left-sidebar.component';
import { HomeTwoComponent } from './home-two/home-two.component';
import { HomeThreeComponent } from './home-three/home-three.component';
import { HomeFourComponent } from './home-four/home-four.component';
import { HomeFiveComponent } from './home-five/home-five.component';
import { CovoiturageComponent } from './products/covoiturage/covoiturage.component';
import { LocationComponent } from './products/location/location.component';


// Routes
const routes: Routes = [
  { path: 'one', component: HomeComponent },
  { path: 'accueil', component: HomeTwoComponent },
  { path: 'three', component: HomeThreeComponent },
  { path: 'four', component: HomeFourComponent },
  { path: 'five', component: HomeFiveComponent },
  { path: 'products/:category', component: ProductLeftSidebarComponent },
  { path: 'location/:category', component: LocationComponent },
  { path: 'covoiturage/:category', component: CovoiturageComponent },
  { path: 'covoiturage/:regionDepart/:regionDestination', component: CovoiturageComponent },
  { path: 'product/:id', component: ProductDetailsComponent },
  { path: 'product/:id/:type_voiture', component: ProductDetailsComponent },
  { path: 'voiture', component: ProductDetailsComponent }


];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ShopRoutingModule { }
