import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-demo',
  templateUrl: './demo.component.html',
  styleUrls: ['./demo.component.scss']
})
export class DemoComponent implements OnInit {

  constructor(
    public router: Router
  ) {
    //redirection a la page de demo utilisée '/home/two'
    this.router.navigateByUrl('/home/two');

  }

  ngOnInit() {
  }

}
