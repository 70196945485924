import { Component, OnInit } from '@angular/core';
import { Product } from 'src/app/modals/product.model';
import { CartItem } from 'src/app/modals/cart-item';
import { ProductService } from '../../shared/services/product.service';
import { CartService } from '../../shared/services/cart.service';

import { CarService } from '../../../services/car.service';

@Component({
  selector: "app-home-two",
  templateUrl: "./home-two.component.html",
  styleUrls: ["./home-two.component.scss"],
})
export class HomeTwoComponent implements OnInit {
  products: Product[];
  public banners = [];

  shoppingCartItems: CartItem[] = [];
  wishlistItems: Product[] = [];

  public featuredProducts: Array<Product>;
  public onSaleProducts: Array<Product>;
  public topRatedProducts: Array<Product>;
  public newArrivalsProducts: Array<Product>;

  public slides = [
    {
      title: "Ford Bmax",
      subtitle: "40 FCFA",
      image: "assets/images/carousel/202009061442551266.jpg",
    },
    {
      title: "Ford Bmax",
      subtitle: "100",
      image: "assets/images/carousel/202008240523272717.jpg",
    },
    {
      title: "Peugeot 206 ",
      subtitle: "200",
      image: "assets/images/carousel/202008240515314394.jpg",
    },
  ];

  public vehicules: any = [];
  public covoiturages: any = [];

  chargement = true;
  erreurChargement = false;

  constructor(
    private productService: ProductService,
    private cartService: CartService,
    private carService: CarService
  ) {}

  ngOnInit() {
    this.carService.getData("").subscribe((vehicules) => {
      this.chargement = false;
      this.vehicules = vehicules;
      this.slides = vehicules.slice(0,3);
    }, (erreur) => {
      this.chargement = false;
      this.erreurChargement = true;
      console.log("Impossible de charger les voitures")
    });

    this.carService.getCovoiturages("").subscribe((vehicules) => {
      this.chargement = false;
      this.covoiturages = vehicules;
      this.slides = vehicules.slice(0,3);
    }, (erreur) => {
      this.chargement = false;
      this.erreurChargement = true;
      console.log("Impossible de charger les voitures")
    });

    this.cartService
      .getItems()
      .subscribe(
        (shoppingCartItems) => (this.shoppingCartItems = shoppingCartItems)
      );
    this.productService.getProducts().subscribe((product: Product[]) => {
      this.products = product;
    });
    this.productService.getBanners().subscribe((data) => (this.banners = data));
  }

  afficherDetail(){

  }


  goVoitureEnLocation(){
    console.log("salut");
  }
}
