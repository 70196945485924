import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders  } from '@angular/common/http';

import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'

import { ApiurlService } from "./apiurl.service";

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/x-www-form-urlencoded',
  }),
}

@Injectable({
  providedIn: 'root'
})
export class CarService {

  url: string;

  voirtureAModifier: any = "";

  constructor(
    private http: HttpClient,
    private apiService: ApiurlService,
  ) {
    this.url = this.apiService.apiUrl + "voiture/";
  }


  getVoitureAmodifier(){
    return this.voirtureAModifier;
  }

  setVoitureAmodifier(voiture){
    this.voirtureAModifier = voiture;
  }


  filtrerParRegion(obj, region) {
    // Si c'est un nombre
    if (obj.region == region) {
      return true;
    } else {
      return false;
    }
  }

  getData(region = '', limit=''): Observable<any> {
    return this.http.get(`${this.url + "list_voiture.php"}`,{
      params: {limit: limit}
    }).pipe(
      map((results) => {
        //console.log('RAW:', results)
        let listVoitures = results["voitures"];
        if (region != '')
          listVoitures = listVoitures.filter(voiture => voiture.region == region);
        return listVoitures;
      })
    );
  }

  getCovoiturages(region = '', limit=''): Observable<any> {
    return this.http.get(`${this.url + "list_voiture_covoiturage.php"}`,{
      params: {limit: limit}
    }).pipe(
      map((results) => {
        //console.log('RAW:', results)
        let listVoitures = results["voitures"];
        if (region != '')
          listVoitures = listVoitures.filter(voiture => voiture.region == region);
        return listVoitures;
      })
    );
  }

  suppressionVoiture(): Observable<any> {
    console.log("salut");
    return this.http.get(`${this.url + "list_voiture.php"}`).pipe(
      map((results) => {
        //console.log('RAW:', results)
        return results["voitures"];
      })
    );
  }

  getMesAnnonces(token): Observable<any> {
    let params = new HttpParams();
    params = params.append("token", token);
    console.log("Token_mes_annonces: " + token);

    return this.http
      .post(this.url + "list_mes_voiture.php", { token }, httpOptions)
      .pipe(
        map((results) => {
          return results["voitures"];
        })
      );
  }

  getUrl() {
    return this.url;
  }

  getDetails(id) {
    return this.http.get(`${this.url}?id=${id}`);
  }

  delCarAnc(id_voiture, token): Observable<any> {
    try {
      let resultat = this.http.post(
        this.url + "supprimer_voiture.php",
        { id_voiture, token },
        httpOptions
      );

      //console.log(resultat);
      return resultat;
    } catch (error) {
      console.log("erreur reseau: ", error);
    }
  }

  delCar(id_voiture, token, type_voiture='location'): Observable<any> {
    try {
      let resultat = this.http.post(
        this.url + "supprimer_voiture.php",
        { id_voiture, token, type_voiture},
        httpOptions
      );

      //console.log(resultat);
      return resultat;
    } catch (error) {
      console.log("erreur reseau: ", error);
    }
  }


  desactiverCar(id_voiture, disponibilite, token, type_voiture='location'): Observable<any> {
    try {
      let resultat = this.http.post(
        this.url + "desactiver_voiture.php",
        { id_voiture, disponibilite, token, type_voiture },
        httpOptions
      );

      //console.log(resultat);
      return resultat;
    } catch (error) {
      console.log("erreur reseau: ", error);
    }
  }

  addCar(data, images) {
    try {
      return this.http.post(
        this.url + "ajout_voiture.php",
        { data, images },
        httpOptions
      );
    } catch (error) {
      console.log("erreur reseau");
    }
  }
}
