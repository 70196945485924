import { Component, OnInit } from '@angular/core';
import { ProductService } from 'src/app/components/shared/services/product.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Product, ColorFilter } from 'src/app/modals/product.model';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';

import { CarService } from 'src/app/services/car.service';

@Component({
  selector: 'app-covoiturage',
  templateUrl: './covoiturage.component.html',
  styleUrls: ['./covoiturage.component.scss']
})
export class CovoiturageComponent implements OnInit {
  public sidenavOpen:boolean = true;
  public animation    :   any;   // Animation
  public sortByOrder  :   string = '';   // sorting
  public page:any;
  public tagsFilters  :   any[] = [];
  public viewType: string = 'grid';
  public viewCol: number = 25;
  public filterForm: FormGroup;
  public colorFilters :   ColorFilter[] = [];

  public items        :   Product[] = [];
  public allItems: Product[] = [];
  public products: Product[] = [];
  public tags         :   any[] = [];
  public colors       :   any[] = [];

  public url: any;

  regionchoisie = "Region";

  prixMinJour : any = 0;
  prixMaxJour : any = 0;
  prixMinKm : any = 0;
  prixMaxKm : any = 0;

  regions_choix = [
    {region: 'Region', libelle: 'Region'},
    {region: 'all', libelle: 'Tous'},
    {region: 'Dakar', libelle: 'Dakar'},
    {region: 'Diourbel', libelle: 'Diourbel'},
    {region: 'Fatick', libelle: 'Fatick'},
    {region: 'Kaffrine', libelle: 'Kaffrine'},
    {region: 'Kaolack', libelle: 'Kaolack'},
    {region: 'Kedougou', libelle: 'Kedougou'},
    {region: 'Kolda', libelle: 'Kolda'},
    {region: 'Louga', libelle: 'Louga'},
    {region: 'Matam', libelle: 'Matam'},
    {region: 'Saint-Louis', libelle: 'Saint-Louis'},
    {region: 'Sedhiou', libelle: 'Sedhiou'},
    {region: 'Tambacounda', libelle: 'Tambacounda'},
    {region: 'Thies', libelle: 'Thies'},
    {region: 'Ziguinchor', libelle: 'Ziguinchor'}
  ]

  public vehicules: any = [];
  public vehiculesSauvegarde: any = [];

  public region = "";

  public regionDepart = "";
  public regionDestination = "";

  chargement = true;
  erreurChargement = false;

  covoiturage = 'covoiturage';

  constructor(
    private productService: ProductService,
    private route: ActivatedRoute,
    private carService: CarService,
    private router: Router
    ) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.route.params.subscribe(
      (params: Params) => {
        this.regionDepart = params['regionDepart'] ?? "";
        this.regionDestination = params['regionDestination'] ?? "";
        const category = params['category'];
      }
    )
  }

  public filtre(){
    console.log(this.prixMinJour);
    console.log(this.prixMaxJour);
    console.log(this.prixMinKm);
    console.log(this.prixMinKm);
    console.log(this.regionchoisie);
  }



     // Get current product tags
     public getTags(products) {
      var uniqueBrands = []
      var itemBrand = Array();
      products.map((product, index) => {
         if(product.tags) {
            product.tags.map((tag) => {
            const index = uniqueBrands.indexOf(tag);
            if(index === -1)  uniqueBrands.push(tag);
         })
        }
      });
      for (var i = 0; i < uniqueBrands.length; i++) {
           itemBrand.push({brand:uniqueBrands[i]})
      }
      this.tags = itemBrand
   }

     // Get current product colors
     public getColors(products) {
      var uniqueColors = []
      var itemColor = Array();
      products.map((product, index) => {
        if(product.colors) {
        product.colors.map((color) => {
            const index = uniqueColors.indexOf(color);
            if(index === -1)  uniqueColors.push(color);
        })
       }
      });
      for (var i = 0; i < uniqueColors.length; i++) {
           itemColor.push({color:uniqueColors[i]})
      }
      this.colors = itemColor
   }

  ngOnInit() {
    this.url = this.carService.getUrl();
    this.carService.getCovoiturages("").subscribe((vehicules) => {
      this.chargement = false;

      console.log(vehicules);

      let vehiculesDepart = vehicules.filter((vehicule)=>{
        if(this.regionDepart=="all" || this.regionDepart==""){
          return vehicule;
        }else{
          return vehicule.region_depart == this.regionDepart;
        }
      });

      let vehiculesDestination = vehiculesDepart.filter((vehicule)=>{
        if(this.regionDepart=="all" || this.regionDepart==""){
          return vehicule;
        }else{
          return vehicule.region_depart == this.regionDepart;
        }
      });

      this.vehicules = vehiculesDepart;

      // this.vehicules = vehicules.filter((vehicule)=>{
      //   if(this.regionDepart=="all" || this.regionDepart==""){
      //     return vehicule;
      //   }else{
      //     return vehicule.region_destination == this.regionDepart;
      //   }
      // });

      this.vehiculesSauvegarde = this.vehicules;

    }, (erreur) => {
      this.chargement = false;
      this.erreurChargement = true;
      console.log("Impossible de charger les voitures")
    });
  }



  async recupVehicules(){
    this.chargement = true;

    let prixMinJour = (parseInt(this.prixMinJour) > 0) ? this.prixMinJour : 0;
    let prixMinKm = (parseInt(this.prixMinKm) > 0) ? this.prixMinKm : 0;
    let prixMaxJour = (parseInt(this.prixMaxJour) > 0) ? this.prixMaxJour : 100000000;
    let prixMaxKm = (parseInt(this.prixMaxKm) > 0) ? this.prixMaxKm : 100000000;

    console.log(this.regionchoisie);

    this.vehicules = this.vehiculesSauvegarde.filter((vehicule) => {
      if(this.regionchoisie=="all" || this.regionchoisie=="" || this.regionchoisie=="Region"){

          return  parseInt(vehicule.prixjour) <= prixMaxJour  && parseInt(vehicule.prix100km) <= prixMaxKm

      }else{
          return vehicule.region == this.regionchoisie && parseInt(vehicule.prixjour) <= prixMaxJour  && parseInt(vehicule.prix100km) <= prixMaxKm
      }

    })

    this.chargement = false;
}



  urlImage(image) {
    let imageurl = this.url + "uploads/" + image;
    return imageurl;
  }



  public changeViewType(viewType, viewCol){
    this.viewType = viewType;
    this.viewCol = viewCol;
  }
    // Animation Effect fadeIn
    public fadeIn() {
      this.animation = 'fadeIn';
  }

  // Animation Effect fadeOut
  public fadeOut() {
      this.animation = 'fadeOut';
  }

    // Update tags filter
    public updateTagFilters(tags: any[]) {
      this.tagsFilters = tags;
      this.animation == 'fadeOut' ? this.fadeIn() : this.fadeOut(); // animation
  }



    // sorting type ASC / DESC / A-Z / Z-A etc.
    public onChangeSorting(val) {
      this.sortByOrder = val;
      this.animation == 'fadeOut' ? this.fadeIn() : this.fadeOut(); // animation
   }

     // Initialize filetr Items
  public filterItems(): Product[] {
    return this.items.filter((item: Product) => {
        const Colors: boolean = this.colorFilters.reduce((prev, curr) => { // Match Color
          if(item.colors){
            if (item.colors.includes(curr.color)) {
              return prev && true;
            }
          }
        }, true);
        const Tags: boolean = this.tagsFilters.reduce((prev, curr) => { // Match Tags
          if(item.tags) {
            if (item.tags.includes(curr)) {
              return prev && true;
            }
          }
        }, true);
        return Colors && Tags; // return true
    });

}

public onPageChanged(event){
  this.page = event;
  this.allItems;
  window.scrollTo(0,0);
}




  // Update price filter
  public updatePriceFilters(price: any) {
    console.log(price);
    console.log(this.products);


   this.allItems = this.products.filter((item: Product) => {
     return item.price >= price.priceFrom && item.price <= price.priceTo
    });
     console.log(this.products);

}

onBrendsChanged(newBrend) {
  console.log(newBrend);
  this.allItems = newBrend === 'all' ? this.products : this.products.filter(

    item => item.brand === newBrend
  )
  console.log(this.allItems);


}
}
