import { Injectable } from '@angular/core'

@Injectable({
  providedIn: 'root',
})
export class ApiurlService {
  //public apiUrl: string = 'https://dawalko.com/api/';
  //public apiUrl: string = "http://localhost/dawal/php/api/";
  public apiUrl: string = 'https://dawalko.com/api/test/';


  constructor() { }

  getUrlApi() {
    return this.apiUrl
  }
}
