import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { ApiurlService } from "./apiurl.service";

import { HttpHeaders } from "@angular/common/http";

import { Md5 } from "md5-typescript";

const httpOptions = {
  headers: new HttpHeaders({
    "Content-Type": "application/x-www-form-urlencoded",
  }),
};

@Injectable({
  providedIn: "root",
})
export class AuthentificationService {
  //apiUsers = "http://localhost/dawal/php/api_online/" +"utilisateur/";
  //apiUsers = "https://niokomom.com/dawal/api/" + "utilisateur/";
  apiUsers = "";
  headers = new Headers({
    "Content-Type": "application/x-www-form-urlencoded",
  });

  infosUser: any = "";
  token: any = "";
  isConnected: any = false;

  reponse: any;

  resultat = localStorage.getItem("infosUser");

  constructor(private http: HttpClient, private apiService: ApiurlService) {
    this.apiUsers = this.apiService.getUrlApi() + "utilisateur/";

    if (this.resultat != null) {
      this.infosUser = this.resultat;
      this.isConnected = true;
      //console.log(this.resultat);
      console.log("utilisateur connecte");
    } else {
      console.log("utilisateur non connecte");
      //console.log(this.resultat);
    }
  }

  setIsConnected(etat = false) {
    this.isConnected = etat;
  }

  getInfosUser() {
    return this.infosUser;
  }

  setInfosUser(infosUser = "") {
    this.infosUser = infosUser;
  }

  getToken() {
    return this.token;
  }

  setToken(token = "") {
    this.token = token;
  }

  connecterUser(email, motdepasse) {
    motdepasse = Md5.init(motdepasse);
    let headers = new Headers({
      "Content-Type": "application/x-www-form-urlencoded",
    });

    return new Promise((resolve, reject) => {
      try {
        return this.http
          .post(
            this.apiUsers + "users_connect.php",
            { email, motdepasse },
            httpOptions
          )
          .subscribe(
            (data) => {
              this.reponse = data;
              if ("etat" in this.reponse) {
                if (this.reponse.etat == "bon") {
                  this.updateProfil(this.reponse.infosUser, this.reponse.token);
                  resolve(this.reponse);
                } else {
                  resolve(this.reponse);
                }
              }
            },
            (error) => {
              reject(error);
            }
          );
      } catch (error) {
        console.log("erreur reseau");
      }
    });
  }

  enregistrerUser(valeurs) {
    let motdepasse = Md5.init(valeurs.motdepasse);
    let motdepasseconfirmer = Md5.init(valeurs.motdepasseconfirmer);
    let prenom = valeurs.prenom;
    let nom = valeurs.nom;
    let email = valeurs.email;
    let telephone = valeurs.telephone;

    return new Promise((resolve, reject) => {
      try {
        return this.http
          .post(
            this.apiUsers + "users_insert.php",
            {
              prenom,
              nom,
              email,
              motdepasse,
              motdepasseconfirmer,
              telephone,
            },
            httpOptions
          )
          .subscribe(
            (data) => {
              this.reponse = data;
              if ("etat" in this.reponse) {
                if (this.reponse.etat == "bon") {
                  this.updateProfil(this.reponse.infosUser, this.reponse.token);
                  resolve(this.reponse);
                } else {
                  resolve(this.reponse);
                }
              }
            },
            (error) => {
              reject(error);
            }
          );
      } catch (error) {
        reject(error);
      }
    });
  }

  modifierUser(valeurs) {
    let prenom = valeurs.prenom;
    let nom = valeurs.nom;
    let email = valeurs.email;
    let telephone = valeurs.telephone;
    let action = "modification";
    let token = this.token;

    return new Promise((resolve, reject) => {
      try {
        return this.http
          .post(
            this.apiUsers + "users_insert.php",
            {
              prenom,
              nom,
              email,
              telephone,
              action,
              token,
            },
            httpOptions
          )
          .subscribe(
            (data) => {
              this.reponse = data;
              if ("etat" in this.reponse) {
                if (this.reponse.etat == "bon") {
                  this.updateProfil(
                    this.reponse.infosUser,
                    this.reponse.token,
                    "modificationProfil"
                  );
                  resolve(this.reponse);
                } else {
                  resolve(this.reponse);
                }
              }
            },
            (error) => {
              reject(error);
            }
          );
      } catch (error) {
        reject(error);
      }
    });
  }

  modifierPassword(valeurs) {
    let ancPass = Md5.init(valeurs.ancPass);
    let nouveauPass = Md5.init(valeurs.nouveauPass);
    let token = this.token;

    return new Promise((resolve, reject) => {
      try {
        return this.http
          .post(
            this.apiUsers + "users_update_password.php",
            {
              ancPass,
              nouveauPass,
              token,
            },
            httpOptions
          )
          .subscribe(
            (data) => {
              this.reponse = data;
              if ("etat" in this.reponse) {
                if (this.reponse.etat == "bon") {
                  resolve(this.reponse);
                } else {
                  resolve(this.reponse);
                }
              }
            },
            (error) => {
              reject(error);
            }
          );
      } catch (error) {
        reject(error);
      }
    });
  }


  

  updatePhotoProfil(photo) {
    let token = this.token;
    return new Promise((resolve, reject) => {
      try {
        return this.http
          .post(
            this.apiUsers + "users_update_image.php",
            {
              token,
              photo,
            },
            httpOptions
          )
          .subscribe(
            (data) => {
              this.reponse = data;
              if ("etat" in this.reponse) {
                if (this.reponse.etat == "bon") {
                  this.updatePhotoUser(this.reponse.photo);
                  resolve(this.reponse);
                } else {
                  resolve(this.reponse);
                }
              }
            },
            (error) => {
              reject(error);
            }
          );
      } catch (error) {
        reject(error);
      }
    });
  }

  updatePhotoUser(photo) {
    this.infosUser.photo = photo;
  }

  updateProfil(infosUser, token, action = "") {

    this.isConnected = true;
    this.infosUser = infosUser;
    if (action == "modificationProfil") {
      let photoUser = this.infosUser.photo;
      this.infosUser.photo = photoUser;
    }
    this.token = token;
    localStorage.setItem("infosUser", JSON.stringify(infosUser));
    localStorage.setItem("token", token);
  }

  nouveauMotDePasse(newTelephone, identifiantType) {
    let telephone = newTelephone;
    let motdepasse = Md5.init(newTelephone);

    return new Promise((resolve, reject) => {
      try {
        return this.http.post(
          this.apiUsers + "nouveau_motdepasse.php",
          //this.apiUsersLocal + "motdepasse_reinit.php",
          {
            telephone,
            motdepasse,
            identifiantType,
          },
          httpOptions
        ).subscribe((data) => {
          resolve(data);
        })
      } catch (error) {
        reject("Erreur: " + error);
      }
    })


  }

  /*nouveauMotDePasse(newTelephone, identifiantType) {
    let telephone = newTelephone;
    let motdepasse = Md5.init(newTelephone);
    try {
      return this.http.post(
        this.apiUsers + "nouveau_motdepasse.php",
        {
          telephone,
          motdepasse,
          identifiantType,
        },
        httpOptions
      );
    } catch (error) {
      console.log("erreur reseau");
    }
  }*/
}
